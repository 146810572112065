import React from "react";
import CoverSVG from "../images/cover_vector_white_transparent.svg";
import ChucksPortrait from "../images/dark_portrait_chuck_white.svg";
import Body from "../images/body_black_transparent.svg";
import Zoom from "react-reveal";
import { Helmet } from "react-helmet";
import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = true;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

function IndexPage() {
  return (
    <div>
      <Helmet>
        <meta chrSet="utf-8" />
        <meta name="description" content="Chuck McClung Mazes Book Website" />
        <meta
          name="keywords"
          content="Maze, Mazes, Maze Book, Mazes Book, Cool Mazes, New Mazes, Fun Mazes"
        />
        <title lang="en">{"Chuck's Crazy Mazes"}</title>
        <link rel="canonical" href="https://chuckscrazymazes.com" />
      </Helmet>
      <div className="container bg-black text-white mx-auto flex flex-col px-10">
        <div className="container mx-auto flex flex-col items-center content-start">
          <div className="flex flex-col justify-start ">
            <Zoom bottom>
              <div className="text-center text-blacksabbath xl:text-6xl lg:text-5xl md:text-5xl text-4xl">
                Chuck&apos;s Crazy Mazes{" "}
                <a
                  className="text-orange-500 xl:text-6xl lg:text-5xl md:text-5xl text-4xl"
                  href="https://www.instagram.com/chuckscrazymazes/"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </div>
              <CoverSVG className="h-screen w-full mx-auto pb-32 pt-10 md:pt-0 md:w-6/12 " />
            </Zoom>
          </div>
          <div className="container flex flex-col items-center content-start">
            <div className="text-2xl md:text-3xl lg:text-4xl leading-tight text-justify mb-10 lg:mx-20 font-abrilfatface ">
              “They that mistake life’s accessories for life itself are like
              them that go too fast in a maze; their very haste confuses them.”
              <div className="text-white text-left font-abrilfatace pt-5 text-xl md:text-2xl">
                SENECA
              </div>
            </div>
            <div
              className="container flex flex-col justify-center items-center pt-10"
              id="bio"
            >
              <Zoom top className="">
                <ChucksPortrait className="container h-auto transition-colors duration-500 stroke-current mx-auto stroke-2 p-12 text-blacksabbath hover:fill-current" />
              </Zoom>
              <Zoom top>
                <div className="text-4xl md:text-6xl leading-none lg:leading-normal pb-10 md:pb-0">
                  Welcome To Chuck&apos;s Crazy Mazes
                </div>
              </Zoom>
              <div className="text-2xl md:text-3xl lg:text-4xl leading-tight text-justify ">
                Welcome to my mazes! My name is Chuck McClung, and I have been
                loving mazes for as long as I can remember. As a young child, I
                studied and solved every maze book I could find. Around 2nd
                grade I started drawing my own mazes. I drew mazes on paper, my
                desk, my friend’s notebook, pieces of wood, anything! Many moons
                later, I’m a botanist, and plants are my calling. Yet I continue
                to draw mazes. They come to life for me, and drawing becomes a
                journey into another crazy universe, a universe that I am
                creating. For some of my mazes, part of the challenge is just
                finding the entrance or exit. To indulge my fondness of the
                logical problems of self-reference, sometimes the entrance or
                exit lies within the maze itself. I feel compelled to share
                these creations, and my aspiration is that you will enjoy your
                own journey in Chuck’s Crazy Mazes.
                <br />
                <div className="text-blacksabbath py-10">
                  Chuck McClung November 2020
                </div>
              </div>
            </div>
            <a href="https://checkout.square.site/merchant/ML2T7ZTZ3ZAZ7/checkout/ZRRWH3HXNM6Z6Z3XRN6JUOKK?src=embed">
              <button
                className="text-blacksabbath bg-transparent border border-solid border-purple-500 hover:bg-purple-500 hover:text-white active:bg-purple-600 font-bold px-8 py-3 rounded outline-none focus:outline-none mr-1 mb-1"
                type="button"
                style={{ transition: "all .15s ease" }}
              >
                Purchase Book
              </button>
            </a>
            <a href="https://www.blurb.com/b/10358599-chuck-s-crazy-mazes">
              <button
                className="text-blacksabbath bg-transparent border border-solid border-purple-500 hover:bg-purple-500 hover:text-white active:bg-purple-600 font-bold px-8 py-3 rounded outline-none focus:outline-none mr-1 mb-1"
                type="button"
                style={{ transition: "all .15s ease" }}
              >
                Purchase Book (outside U.S.)
              </button>
            </a>
            <div className="text-blacksabbath py-10">
              For special orders and bulk discounts, contact Chuck at{" "}
              <a href="mailto:chuckscrazymazes@gmail.com">
                chuckscrazymazes@gmail.com
              </a>
            </div>
            <div className="">
              <Zoom top>
                <Body className="h-auto lg:h-screen fill-current text-white w-10/12 mx-auto mt-10 " />
              </Zoom>
            </div>
          </div>
        </div>

        <div className="font-mono text-lg text-white text-center pt-10">
          Copyright © Chuck McClung 2020
        </div>
      </div>
    </div>
  );
}

export default IndexPage;
